import React, { useState } from "react"
import "./style.scss"
import UpgradePlanModal, { User, URLs } from "app/components/upgrade_plan_modal"

interface Props {
  promoCode: string
  urls: URLs
  user: User
}

const HolidayBanner = ({ promoCode, urls, user }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const onUpgrade = () => {
    setShowModal(false)

    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  return (
    <>
      <UpgradePlanModal
        initialPromoCode={promoCode}
        holidayPromoCode={promoCode}
        onClose={() => setShowModal(false)}
        onUpgrade={onUpgrade}
        proPlusProductId="pro-plus-annual"
        step="holiday"
        tierRequirement="intermediate_pro"
        user={user}
        visible={showModal}
        urls={urls}
      />
      <div styleName="holiday-banner" role="button" aria-label="Holiday banner" onClick={() => setShowModal(true)}>
        Tech the halls with our best prices of the season. <span styleName="cta">Get 24% off Pro</span> 🎁
      </div>
    </>
  )
}

export default HolidayBanner
